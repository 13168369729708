import { ContentWrapper } from "@components-v3/ContentWrapper";
import { ReviewCard } from "@components-v3/ReviewCard";
import { CaseStudiesSection } from "@components-v3/Sections/CaseStudiesSection";
import { ImageSection } from "@components-v3/Sections/ImageSection";
import { ReviewsSection } from "@components-v3/Sections/ReviewsSection";
import { ScrollingLogoSection } from "@components-v3/Sections/ScrollingLogoSection";
import LayOut from "@components/layout";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import LeftRightBlock from "@components/shared/sub/leftRightBlock";
import { Query, SanityHomePageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";
import { graphql, PageProps } from "gatsby";
import React, { useEffect } from "react";
import { StyledLRBlockSection } from "./featuresPage";
import Cta from "@components/shared/cta";
import { Container } from "@util/standard";

interface Data extends Query {
  altRegions: SanityHomePageConnection;
}

interface HomePageContext {
  iban: string;
  slug: string;
}

interface HomeV3Props extends PageProps {
  data: Data;
  pageContext: HomePageContext;
}

const HomeV3 = ({ data, pageContext }: HomeV3Props) => {
  const { nodes } = data.allSanityHomePageV3;
  const sanityHomePage = nodes[0];
  const alternateRegions = data.altRegions.nodes.map(alt => alt._rawRegion);

  useRegionChanged("", true);
  //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    homeHero,
    imageSection,
    accruedLogos,
    securityBlock,
    customerReviews,
    caseStudies,
    showcaseSection,
    plansCtaSection,
    demoCtaSection,
  } = sanityHomePage;

  return (
    <>
      <SEO
        seoData={sanityHomePage.seo}
        alternateRegions={alternateRegions}
        slug={pageContext.slug}
      />
      <LayOut>
        <Hero heroContent={homeHero} featuredHero />
        {!!accruedLogos && (
          <ScrollingLogoSection
            title={accruedLogos.title ?? ""}
            buttonText={accruedLogos.button?.displayName?.default ?? ""}
            url={accruedLogos.button?.url ?? ""}
            logos={accruedLogos!.logoImages!.map(
              i => i!.asset!.gatsbyImageData
            )}
          />
        )}
        <ImageSection
          title={imageSection?.text?.title ?? ""}
          description={imageSection?.text?.description ?? ""}
          button={imageSection?.text?.headerButton ?? {}}
          image={imageSection?.image?.asset?.gatsbyImageData}
          linkStyle="link"
          isBlue
        />
        <StyledLRBlockSection isWhite style={{ margin: 0 }}>
          <ContentWrapper style={{ margin: 0 }}>
            <LeftRightBlock imageSize={400} content={securityBlock} isReverse />
          </ContentWrapper>
        </StyledLRBlockSection>
        {!!customerReviews && (
          <ReviewsSection
            title={customerReviews.title ?? ""}
            buttonUrl={customerReviews.button!.url ?? ""}
          >
            {customerReviews.customers!.map(r => (
              <ReviewCard
                image={r!.customer!.image!.asset!.gatsbyImageData}
                name={r?.customer?.name ?? ""}
                jobTitle={r?.customer?.jobTitle ?? ""}
                companyName={r?.customer?.workspace ?? ""}
                content={r!.quote!._rawContent}
              />
            ))}
          </ReviewsSection>
        )}
        {!!caseStudies && (
          <CaseStudiesSection
            caseStudies={caseStudies.informationCards!.map(ic => {
              return {
                image: ic!.icon!.asset!.gatsbyImageData,
                title: ic?.title ?? "",
                description: ic?.description ?? "",
              };
            })}
            sectionTitle={caseStudies.title ?? ""}
            sectionDescription={caseStudies.description ?? ""}
            buttonUrl={caseStudies.button?.url ?? ""}
          />
        )}
        <StyledLRBlockSection>
          <LeftRightBlock content={showcaseSection} isReverse />
        </StyledLRBlockSection>
        <Cta
          content={plansCtaSection}
          noDownloadButtons
          isCenterContent
          showButtonInMobile
        />
        <Container backgroundColor="babyBlue" paddingVertical={5}>
          <Cta content={demoCtaSection} noDownloadButtons showButtonInMobile />
        </Container>
      </LayOut>
    </>
  );
};
export default HomeV3;
export type { HomeV3Props };

export const query = graphql`
  query HomeV3Query($iban: String) {
    allSanityHomePageV3(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        homeHero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        imageSection {
          text {
            headerButton {
              linkUrl
              linkText
              isInternal
            }
            title
            description
          }
          image {
            ...sanityImageWithMeta
          }
        }
        securityBlock {
          alignment
          blockImage {
            ...sanityImageWithMeta
          }
          blockText {
            _rawContent
          }
        }
        customerReviews {
          title
          button {
            ...navigationLinkFragment
          }
          customers {
            customer {
              workspace
              name
              jobTitle
              image {
                ...sanityImageWithMeta
              }
            }
            quote {
              _rawContent
            }
          }
        }
        caseStudies {
          title
          description
          button {
            ...navigationLinkFragment
          }
          informationCards {
            title
            description
            icon {
              ...sanityImageWithMeta
            }
          }
        }
        showcaseSection {
          alignment
          blockImage {
            ...sanityImageWithMeta
          }
          blockText {
            _rawContent
          }
        }
        blogPosts {
          title
          content {
            image {
              ...sanityImageWithMeta
            }
            button {
              ...navigationLinkFragment
            }
          }
          description {
            _rawContent
          }
        }
        accruedLogos {
          title
          button {
            ...navigationLinkFragment
          }
          logoImages {
            ...sanityImageWithMeta
          }
        }
        plansCtaSection {
          ctaButton {
            internallink
            linkDescription
            linkStyle
            linktext
            url
            newwindow
            navigateBack
          }
          ctaImage {
            ...sanityImageWithMeta
          }
          ctaText {
            _rawContent(resolveReferences: { maxDepth: 10 })
          }
        }
        demoCtaSection {
          ctaButton {
            internallink
            linkDescription
            linkStyle
            linktext
            url
            newwindow
            navigateBack
          }
          ctaImage {
            ...sanityImageWithMeta
          }
          ctaText {
            _rawContent(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
    altRegions: allSanityHomePageV3 {
      nodes {
        _rawRegion(resolveReferences: { maxDepth: 10 })
      }
    }
    sanityUseApprovedsIndex {
      _rawLogosArray(resolveReferences: { maxDepth: 5 })
      logosArray {
        _key
        locationsArray {
          _key
          name
          value
        }
        title
        logos {
          _key
          title
          image {
            altText
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 75
                height: 75
                fit: CLIP
              )
            }
          }
        }
      }
    }
  }
`;
